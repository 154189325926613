// import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import webLogo from "./assests/webLogo.png";
import { useEffect, useRef, useState } from "react";

function App() {
  const [first, setFirst] = useState(false);
  const [err, setErr] = useState();

  const [loading, setLoading] = useState(false);
  const falsRef = useRef(null);
  const IagesMemRef = useRef(null);
  const nonIagesRef = useRef(null);
  const trainingRef = useRef(null);

  const checkMail = async (e) => {
    setErr("");
    setLoading(true);
    e.preventDefault();
    const mail = e.target[0].value;
    await axios
      .get(`https://api.surgtest.com/user-exists/${mail}/`)
      .then((res) => {
        setFirst(res.data === "True" ? true : false);
        setTimeout(() => {
          setErr(res.data === "True" ? true : false);
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err.response.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  };

  useEffect(() => {
    if (first) {
      if (falsRef.current) {
        const script = document.createElement("script");
        script.async = true;
        script.setAttribute("data-payment_button_id", "pl_KI3d4AzGMFlUTs");
        script.src = "https://checkout.razorpay.com/v1/payment-button.js";
        falsRef.current.innerHTML = "";
        falsRef.current.appendChild(script);
      }
      if (IagesMemRef.current) {
        const script = document.createElement("script");
        script.async = true;
        script.setAttribute("data-payment_button_id", "pl_KI3UwYVAeNqnP8");
        script.src = "https://checkout.razorpay.com/v1/payment-button.js";
        IagesMemRef.current.innerHTML = "";
        IagesMemRef.current.appendChild(script);
      }
      if (nonIagesRef.current) {
        const script = document.createElement("script");
        script.async = true;
        script.setAttribute("data-payment_button_id", "pl_KI3WAzmBXANrK5");
        script.src = "https://checkout.razorpay.com/v1/payment-button.js";
        nonIagesRef.current.innerHTML = "";
        nonIagesRef.current.appendChild(script);
      }
      if (trainingRef.current) {
        const script = document.createElement("script");
        script.async = true;
        script.setAttribute("data-payment_button_id", "pl_KI3U43bVe5Z7OT");
        script.src = "https://checkout.razorpay.com/v1/payment-button.js";
        trainingRef.current.innerHTML = "";
        trainingRef.current.appendChild(script);
      }
    } else {
      if (falsRef.current) {
        const script = document.createElement("script");
        script.async = true;
        script.setAttribute("data-payment_button_id", "pl_KI3NZzQ20v9LdR");
        script.src = "https://checkout.razorpay.com/v1/payment-button.js";
        falsRef.current.innerHTML = "";
        falsRef.current.appendChild(script);
      }
      if (IagesMemRef.current) {
        const script = document.createElement("script");
        script.async = true;
        script.setAttribute("data-payment_button_id", "pl_KI3L0dER8MOLeX");
        script.src = "https://checkout.razorpay.com/v1/payment-button.js";
        IagesMemRef.current.innerHTML = "";

        IagesMemRef.current.appendChild(script);
      }
      if (nonIagesRef.current) {
        const script = document.createElement("script");
        script.async = true;
        script.setAttribute("data-payment_button_id", "pl_KI3MgqchSIRaJ8");
        script.src = "https://checkout.razorpay.com/v1/payment-button.js";
        nonIagesRef.current.innerHTML = "";

        nonIagesRef.current.appendChild(script);
      }
      if (trainingRef.current) {
        const script = document.createElement("script");
        script.async = true;
        script.setAttribute("data-payment_button_id", "pl_KHua6S3o64HYJs");
        script.src = "https://checkout.razorpay.com/v1/payment-button.js";
        trainingRef.current.innerHTML = "";

        trainingRef.current.appendChild(script);
      }
    }
  }, [first]);

  return (
    <>
      <div className="background-page">
        <div className="main_modal">
          <div className="imgDiv">
            <img
              style={{ height: "100%", width: "100%" }}
              src={webLogo}
              alt="web"
            />
          </div>
          <div className="main_title">Surgtest Course Details</div>
          <form autoComplete="off" onSubmit={checkMail} className="model_form">
            <div class="input-container ic1">
              <input
                id="firstname"
                class="input"
                type="email"
                placeholder="Enter Your Mail "
                required
              />
            </div>
            {err ? (
              <p className="successMsg">
                &#x1F600; Congratulations! You are eligible for this offer...
              </p>
            ) : err === false ? (
              <p className="ErrorMsg">
                &#x1F615; Sorry! You are not eligible for this offer...
              </p>
            ) : (
              ""
            )}
            {loading ? (
              <p className="check">Checking</p>
            ) : (
              <button type="submit" className="check_for_offer">
                Check for Offer
              </button>
            )}
          </form>
          <div className="desDiv">
            <p className="descriptionHeading">
              {" "}
              <span className="stepHeading">Enrolment process –</span> What to
              expect:
            </p>
            <p className="description">
              <span className="step">Step 1 -</span> Select category & pay{" "}
              <br />{" "}
              <i className="identify">
                (You will receive a payment confirmation)
              </i>
            </p>
            <p className="description">
              <span className="step">Step 2 -</span> Submit proof of IAGES
              membership & student status by filling out a google form <br />
              <p className="step">
                (Step 2 & 3{" "}
                <span className="descrip">
                  are ONLY relevant to CME or live workshop attendees availing a
                  discount based on their student or IAGESmembership status and
                  to all FALS course participants)
                </span>
              </p>
              <i className="identify">
                (Please submit necessary paperwork within 1 working day from the
                date of payment in order to secure your spot. status by filling
                out a google form){" "}
              </i>
            </p>

            <p className="description">
              <span className="step">Step 3 – </span>Verification of documents
            </p>
            <p className="description">
              <span className="step">Step 4 -</span> Enrolment confirmation
            </p>
            <i className="identify">
              (Verification & Enrollment confirmation can take upto 2 working
              days)
            </i>
          </div>

          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th>Courses</th>
                  <th>Course Fees</th>
                  <th>GST</th>
                  <th>Total Fees</th>
                  <th>Payment</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Courses">FALS Course Registration Fees </td>
                  <td data-label="Course Fees"> ₹25,000 </td>
                  <td data-label="GST">₹4,500 </td>
                  {first ? (
                    <td data-label="Total Fees">
                      <del style={{ marginRight: "1vw", color: "#58585" }}>
                        ₹29,500{" "}
                      </del>{" "}
                      ₹25,075{" "}
                    </td>
                  ) : (
                    <td data-label="Total Fees">₹29,500</td>
                  )}

                  <td className="payBtn">
                    <form ref={falsRef}></form>
                  </td>
                </tr>

                <tr>
                  <td data-label="Courses">
                    CME/Live Workshop delegate(Non IAGES Member){" "}
                  </td>
                  <td data-label="Course Fees">₹8,000</td>
                  <td data-label="GST">₹1,440 </td>
                  {first ? (
                    <td data-label="Total Fees">
                      <del style={{ marginRight: "1vw", color: "#58585" }}>
                        ₹9,440{" "}
                      </del>{" "}
                      ₹8,024{" "}
                    </td>
                  ) : (
                    <td data-label="Total Fees">₹9,440</td>
                  )}
                  <td className="payBtn">
                    <form ref={nonIagesRef}></form>
                  </td>
                </tr>
                <tr>
                  <td data-label="Courses">
                    CME/Live Workshop delegate IAGES Member{" "}
                  </td>
                  <td data-label="Course Fees">₹6,000 </td>
                  <td data-label="GST">₹1,080 </td>
                  {first ? (
                    <td data-label="Total Fees">
                      <del style={{ marginRight: "1vw", color: "#58585" }}>
                        ₹7,080{" "}
                      </del>{" "}
                      ₹6018{" "}
                    </td>
                  ) : (
                    <td data-label="Total Fees">₹7,080</td>
                  )}
                  <td className="payBtn">
                    <form ref={IagesMemRef}></form>
                  </td>
                </tr>
                <tr>
                  <td data-label="Courses">
                    CME/Live Workshop Residelnt In Training{" "}
                  </td>
                  <td data-label="Course Fees">₹4,000 </td>
                  <td data-label="GST">₹7,20 </td>
                  {first ? (
                    <td data-label="Total Fees">
                      <del style={{ marginRight: "1vw", color: "#58585" }}>
                        ₹4,720{" "}
                      </del>{" "}
                      ₹4,012{" "}
                    </td>
                  ) : (
                    <td data-label="Total Fees">₹4,720</td>
                  )}
                  <td className="payBtn">
                    <form ref={trainingRef}></form>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
